

    .displayTopFlex{
      flex-wrap: wrap-reverse;
    }



    .backgroundImage{
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      min-height: 200px;
      position: absolute;
      background-size: cover;
      background-position: center;
    }


