.mobile-nav, #mobile-menu button{
  text-align: left;
  font-size: 1.2rem;
  transition: all .2s ease-in-out;
}

#mobile-menu button{
  margin: 0px;
  padding: 0px;
}

.mobile-nav li a, #mobile-menu li, #mobile-menu button{
  /* color: black; */
  position: relative;
}

.sub-menu ul li{
  list-style: none;
}

.mobile-nav .sub-menu ul{
margin: 0px;
padding: 10px 0px;
}

.sub-menu{
  overflow: hidden;
}

.sub-menu ul{
  margin: 0px;
}

.sub-menu ul li {
  padding: 12px 22px 12px 22px !important;
  margin: 0.8em;
  }

.mobile-nav .menulink input:checked + button .sub-menu{
  display: block !important;
  box-shadow: none;
}

#mobile-menu li{
margin-top: 0.5rem;
}

.menulink{
  font-weight: 500;
  font-size: 1.8em;
}

#desktop-menu .menulink{
  padding-top: 8px;
  margin-right: 1rem;
  transition: all 1.5s ease;
  position: relative;
}

.header-nav{
  border-radius: 25em 3.5em 3.5em 25em !important;
  border: 2px solid #ffffff;
  height: 60px;
  z-index: 999;
}

#desktop-menu li .sub-menu{
  position: absolute;
  background: white;
  border-radius: 15px;
  right: -50%;
  width: max-content;
}


.menu-icon{
  width: 35px;
  height: intrinsic;
  border-radius: 25rem;
  margin-right: 12px;
  top: -6px;
  position: relative;
}


.sub-menu a{
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  transition: all 1.5s ease;
}


#desktop-menu li a, #desktop-menu .menulink{
  color: black;
  font-size: 1rem;
  transition: all .2s ease-in-out;
}

* .mobile-nav .sub-menu .menulink a{
  color: #ffffff !important;
  font-size: 30px !important;
  transition: all .2s ease-in-out;
}

#desktop-menu li a:hover, .mobile-nav li a:hover {
  /* color: red; */
}

#desktop-menu {
  margin-top: 0.5rem;
}

.logoTypeWrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.textNone {
  left: -100%;
  transition: all 0.5s;
  transform: translateY(1.5);
}

.regular{
  left: 0;
  transition: all 0.5s;
  transform: translateY(-100%);

}



@media only screen and (min-width: 767px) {

  .mobile-menu-wrapper{
    display: none;
  }
}