.crossImage{
  min-width: 60px;
  width: 15%;
  max-width: 150px;
}

.bottomLineStyle{
  min-width: 150px;
  width: 40%;
  height: 300px;   
}

.topLineStyle{
  min-width: 150px;
  width: 30%;
  height: 300px;  
}

.pagination_button {
  justify-items: center;
  height: 35px;
  width: 35px;
  padding: 4px 8px;
  justify-content: center;
  display: flex;
}
@media screen and (max-width: 768px) {
  .bottomLineStyle{
    min-width: 150px;

    height: 200px;   
  }

  .topLineStyle{
    min-width: 150px;

    height: 200px;   
  }
}

@media screen and (max-width: 600px) {
  .bottomLineStyle{
    min-width: 150px;
    width: 80%;
    height: 200px;   
  }

  .catImg{
    display: none;
  }


  .topLineStyle{
    min-width: 150px;
    width: 80%;
    height: 200px;   
  }
}
