@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,800;1,400;1,500;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@tailwind base;


h6{
  @apply text-base;
}


h5{
  font-size: 1.1em;
}

h4{
  font-size: 1.2em;
}

h3{
  font-size: 1.4em;
}

h2{
  font-size: 1.8em;
}

h1{
  font-size: 2em;
}

*{
font-family: 'Lato';
font-weight: 400;
}

p a {
  text-decoration: underline;
}

button{
  font-weight: 500 !important;
  text-decoration: none;
}

nav li a, footer p a, footer li a{
  text-decoration: none;
}
  
h1, h2, h3, h4, h5, h6 {
    @apply font-semibold;
    margin-bottom: 1.2rem;
    @apply font-heading;
    font-weight: 500;
  }


  body p {
    margin-bottom: 1rem;
    @apply leading-base;
  }

    ul, ol {
      list-style: revert;
      margin: 2rem ;
    }

    ul li, ol li {
      margin-bottom: 0.8rem ;
    } 

  .background-image{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.3;
    top: 0;
    width: 100%;
  }

  .background-overlay{
    height: 100%;
    width: 100%;
  }

  .form-floating input {
    margin-bottom: 2rem;
  }

  .outlineText {
    @apply text-dark;
    text-shadow: -1px -1px 0 rgb(255, 255, 255), 1px -1px 0 rgb(255, 255, 255), -1px 1px 0 #fff, 1px 1px 0 #fff;
  }

  .react-multi-carousel-list{
    overflow: visible;
  }
  /***** The important stuff *****/
.underlineSvg{position: relative; margin-bottom: 5rem; width: 100%}
.underlineSvg:before {
  content: "";
  position:relative;
  width:100%;
  height: 2.45em;
  right: 0;
  max-width: 200px;
  /*z-index:-10;*/
  background-image: url('./components/images/assets/underline.svg');
  background-repeat: no-repeat;	
  background-size: 100% 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.underlineSvg:after {
  content: "";
  position: absolute;
  width:100%;
  height: 2.80em;
  right: 0;
  max-width: 200px;
  /*z-index:-10;*/
  background-image: url('./components/images/assets/underline.svg');
  background-repeat: no-repeat;	
  background-size: 100% 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

  /***** The important stuff *****/
  .toplinesSvg{position: relative; width: 100%}
  .toplinesSvg:before {
    content: "";
    position: relative;
    /*z-index:-10;*/
    background-image: url('./components/images/assets/topthreelines.svg');
    background-repeat: no-repeat;	
    background-size: 100% 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }
  .toplinesSvg:after {
    content: "";
    position: absolute;
    width: 80px;
    height: 1.20em;
    left: -50px;
    top: -60%;
    /*z-index:-10;*/
    background-image: url('./components/images/assets/topthreelines.svg');
    background-repeat: no-repeat;	
    background-size: 100% 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  button{
    border-radius: 25rem;
  }

  @media only screen and (max-width: 800px) {

  }


@tailwind components;
@tailwind utilities;

