/** Some basic styles to make things look nice.**/
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Oswald');

.wp-block-columns, .wp-block-media-text, .wp-block-buttons {
  display: flex;
  max-width: 960px;
  margin: 0.8em auto;
}

.has-white-color{
    color: white;
}

.wp-block-button a{
    padding: 0.8em 2.4em;
    text-align: center;
}

.is-style-fill a{
    @apply bg-primary;
}

.is-style-outline a{
    border-width: 2px; 
}

.is-style-outline a{
    @apply text-primary;
}

.wp-block-button__width-50 a{
    width: 50%;
}

.wp-block-button__width-25 a{
    width: 25%;
}

.wp-block-button__width-75 a{
    width: 75%;
}

.wp-block-button__width-100 a{
    width: 100%;
}

.is-style-fill a{
    @apply text-light;
}

.has-extra-small-font-size{
    @apply text-sm;
}

.has-large-font-size{
    @apply text-xl;
}

.has-extra-large-font-size{
    @apply text-3xl;
}

.has-huge-font-size{
    @apply text-5xl;
    line-height: 1.1em;
}

.has-gigantic-font-size{
    @apply text-8xl;
    line-height: 1.1em;
}

.wp-block-column, .wp-block-media-text__content, .wp-block-media-text__media {
  flex: 1;
  margin: auto;
}

.wp-block-media-text__media, .wp-block-column, .wp-block-media-text__content, .wp-block-buttons{
padding: 0.8em 0.4em;
}

.wp-block-quote{
    max-width: 960px;
    margin: 2em auto;
    padding: 2em 2.8em;
    @apply bg-light;
    border-width: 5px;
    border-top: 0px; 
    border-right: 0px; 
    border-bottom: 0px; 
    @apply border-primary;
}



.wp-block-pullquote blockquote{
    padding: 0.8em 0.4em;
    max-width: 960px;
    margin: 2em auto;
    padding: 2em 1.8em;
    border-width: 5px !important;
    border-left: 0px; 
    border-right: 0px; 
    text-align: center;
    @apply border-dark;
}

.wp-block-verse{
    overflow-wrap: break-word;
}

.wp-block-cover{
    padding: 4em 0;
    position: relative;
}

.wp-block-cover__background{
    padding: 4em;
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
}

blockquote p {
 font-size: 2em;
 font-weight: 600;
 font-family: "Montserrat", "poppins", "Helvetica", "sans-serif";
}

.wp-block-image .aligncenter{
    display: flex;
    justify-content: center;
}

.wp-block-gallery{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.is-cropped .wp-block-image{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.is-cropped .wp-block-image figcaption{
    background-color: white;
    position: absolute;
    width: 95%;
    bottom: 0px;
    text-align: center;
    margin-bottom: 0.4em;
}

.is-cropped .wp-block-image img{
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
}

.columns-4 .wp-block-image{
    flex: 1 1 25%;
}

td, th {
    border: 1px solid #777;
    padding: 0.5rem;
    text-align: center;
}
 
table {
    border-collapse: collapse;
    width: 100%;
}
 
tbody tr:nth-child(odd) {
    background: #eee;
}
caption {
    font-size: 0.8rem;
}

@media (max-width: 764px) {

    .wp-block-columns, .wp-block-media-text, .wp-block-buttons {
        display: block;
        max-width: 960px;
        margin: 0.8em auto;
      }

      .has-extra-small-font-size{
        @apply text-sm;
    }
    
    .has-large-font-size{
        @apply text-xl;
    }
    
    .has-extra-large-font-size{
        @apply text-2xl;
    }
    
    .has-huge-font-size{
        @apply text-3xl;
        line-height: 1.1em;
    }
    
    .has-gigantic-font-size{
        @apply text-5xl;
        line-height: 1.1em;
    }

  .wp-block-columns.has-3-columns {
    flex-flow: row wrap;
  }

  .has-3-columns .wp-block-column:first-child {
    flex-basis: 100%;
  }
}

@media (max-width: 478px) {
  .wp-block-columns.has-3-columns {
    display: block;
  }

  .wp-block-column {
    margin: 20px 0;
  }
}