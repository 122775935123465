
.stepper-li{
position: relative;
 }

 .stepper-li div{
    z-index: 10;
     }

 
.stepper-li:nth-child(1)::after{
        position: absolute;
       border-bottom: #f4f4f4;
       width: 50% !important;
       @apply bg-primary;
       height: 2px;
       content: "";
       right: 0px;
    }

    .stepper-li::after{
        position: absolute;
       border-bottom: #f4f4f4;
       width: 100% !important;
       @apply bg-primary;
       height: 2px;
       content: "";
       right: 0px;
    }


     
.stepper-li:last-child::after{
    position: absolute;
   border-bottom: #f4f4f4;
   width: 50% !important;
   @apply bg-primary;
   height: 2px;
   content: "";
   left: 0px;
}
